// main.js | @/main.js
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueGtag from "vue-gtag";
import { createHead } from "@vueuse/head";
import { createPinia } from "pinia";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons */
import {
  faBook,
  faBookOpen,
  faParachuteBox,
  faCoins,
  faMap,
  faGlobe,
  faCode,
  faMagnifyingGlass,
  faTimes,
  faCircleXmark,
  faTableCellsLarge,
  faTableCells,
  faGrip,
  faArrowUp,
  faArrowDown,
  faChartPie,
  faBookmark,
  faBars,
  faEnvelope,
  faShield,
  faClock,
  faArrowLeft,
  faChevronRight,
  faChevronLeft,
  faBell,
  faTrashAlt,
  faStar,
  faEye,
  faGraduationCap,
  faArrowsRotate,
  faEdit,
  faUserCircle,
  faCog,
  faPiggyBank,
  faHand,
  faGauge,
  faGear,
  faHandshakeSimple,
  faKey,
  faFlaskVial,
} from "@fortawesome/free-solid-svg-icons";

import {
  faInstagram,
  faTwitter,
  faGithub,
  faSquareXTwitter,
  faSquareGithub,
  faDiscord,
  faTelegram,
  faGoogle,
} from "@fortawesome/free-brands-svg-icons";

/* add icons to the library */
library.add(
  faInstagram,
  faTwitter,
  faGithub,
  faSquareXTwitter,
  faSquareGithub,
  faDiscord,
  faTelegram,
  faGoogle,
  faBook,
  faBookOpen,
  faParachuteBox,
  faCoins,
  faMap,
  faGlobe,
  faCode,
  faMagnifyingGlass,
  faTimes,
  faCircleXmark,
  faMagnifyingGlass,
  faTableCells,
  faTableCellsLarge,
  faGrip,
  faArrowUp,
  faArrowDown,
  faChartPie,
  faBookmark,
  faBars,
  faEnvelope,
  faShield,
  faClock,
  faArrowLeft,
  faChevronLeft,
  faChevronRight,
  faBell,
  faTrashAlt,
  faStar,
  faEye,
  faGraduationCap,
  faArrowsRotate,
  faEdit,
  faUserCircle,
  faCog,
  faPiggyBank,
  faHand,
  faGauge,
  faGear,
  faHandshakeSimple,
  faKey,
  faFlaskVial
);

import "solana-wallets-vue/styles.css";
import "@/main.css";

import Toast from "vue3-toastify";
import "vue3-toastify/dist/index.css";

//Add the solana wallets
import SolanaWallets from "solana-wallets-vue";

import {
  // BackpackWalletAdapter,
  // SolflareWalletAdapter,
  BraveWalletAdapter,
} from "@solana/wallet-adapter-wallets";

const walletOptions = {
  wallets: [
    // new BackpackWalletAdapter(),
    // new SolflareWalletAdapter(),
    new BraveWalletAdapter(),
  ],
  autoConnect: false,
};

//sync(store, router);

const app = createApp(App);
app.use(
  VueGtag,
  {
    config: { id: "G-QCLZ3VLH1P" },
  },
  router
);
const head = createHead();
app.use(head);
app.use(router);
app.use(createPinia());
app.use(SolanaWallets, walletOptions);
app.use(Toast, {
  position: "bottom-right",
  transition: "Vue-Toastify__bounce",
  maxToasts: 3,
  newestOnTop: true,
});
app.component("font-awesome-icon", FontAwesomeIcon);
app.mount("#app");
